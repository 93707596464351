(function() {
  var navIcon = document.querySelector('.header-nav_button');
  var nav = document.querySelectorAll('.js-toggle_nav');
  var squares = document.querySelectorAll('.js-animate_squares');


  // Show nav by tapping/clicking on the nav icon
  navIcon.addEventListener('click', showNav);

  function showNav() {
    animateNav();
    animateSquares();
  }

  function animateNav() {
    for (var i = 0; i < nav.length; i++) {
      nav[i].classList.toggle('is_nav_shown');
    }
  }

  function animateSquares() {
    for (var j = 0; j < squares.length; j++) {
      squares[j].classList.toggle('is_nav_shown');
    }
  }
})();
