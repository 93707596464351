(function() {
  var homeBody = document.querySelector('.js-welcome');

  if (!homeBody) {
    return;
  }

  // Re-enable the scroll after the welcome animation is finished
  setTimeout(removeOverflow, 2000);

  function removeOverflow() {
    homeBody.classList.remove('is-fixed');
  }
})();
